






























































































    import {Component, Vue} from "vue-property-decorator";
    import api from "@/api";
    import {imageUrl, showTagRE} from '@/utils/common'
    import uploadNew from "@/components/uploadNew.vue";
    import {base64DownloadFile, ExportExcel} from "@/utils/exportFile";
    import store from "@/store";

    @Component({
        name: "shareList",
        components: {
            uploadNew: uploadNew
        }
    })
    export default class AUser extends Vue {
        baseUrl = imageUrl

        // 表格数据
        tableData = [];
        // 加载状态
        loading = false;
        // 总条数
        total = 0;
        // 搜索条件
        search: any = {
            keyword: '',
            status: '',
            pagination: {
                page: 0, //页码，从0开始
                pageSize: 20, //每页数量
            },
        };

        exportD() {
            api.getN('J27532?token=' + store.state.user.token + '&keyword=' + this.search.keyword + '&status=' + this.search.status + '&questId=' + this.$route.query.questId).then(res => {
                // 假设 response 是后端返回的包含数据流的响应对象
                // 创建blob对象，解析流数据
                const blob = new Blob([res], {
                    // 设置返回的文件类型
                    type: 'application/octet-stream'
                })
                // 这里就是创建一个a标签，等下用来模拟点击事件
                const a = document.createElement('a')
                const URL = window.URL || window.webkitURL
                const herf = URL.createObjectURL(blob)
                a.href = herf
                a.download = '【分享有礼名单' + new Date().toLocaleString() + '】.xlsx'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                window.URL.revokeObjectURL(herf)

            })
        }


        sure() {
            if (this.file.fileCode == '') {
                this.$message.warning('请上传文件')
                return
            }
            api.post('J27527', {...this.file, id: this.info.id}).then(res => {
                this.$message.success('操作成功')
                this.jifenDialog = false
            })
        }

        shInfo = {}
        shDialog = false
        toSH(row: any) {
            this.shInfo = {...row}
            this.fileList = {
                image_url: row.reserve1,
                image_name: row.reserve1
            }
            this.shDialog = true
        }

        handleCloseSH() {
            this.shDialog = false
        }

        sureSH() {
            api.post('J27535', this.shInfo).then(res => {
                this.$message.success('操作成功')
                this.shDialog = false
                this.searchTable()
            })
        }

        // 下载图片
        // 加载图片
        async loadImage(params: any) {
            // 图片src 必传
            let src = params.src
            return new Promise((resolve, reject) => {
                let img = new Image()
                img.src = src
                img.crossOrigin = 'anonymous'
                img.onload = () => {
                    resolve(img)
                }
                img.onerror = reject
            })
        }

// 图片转base64
        img2Base64(image: any) {
            // 图片画到canvas
            let canvas: any = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);
            return canvas.toDataURL('image/png', 1.0)
        }

// base64转blob
        base64ToBlob(base64Code: any) {
            let parts = base64Code.split(';base64,')
            let contentType = parts[0].split(':')[1]
            let raw = window.atob(parts[1])
            let rawLength = raw.length
            let uInt8Array = new Uint8Array(rawLength)
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i)
            }
            return new Blob([uInt8Array], {
                type: contentType
            })
        }

        downloadFile(href: any, filename: any) {
            const link = document.createElement('a');
            link.href = href;
            link.download = filename;
            link.setAttribute('type', 'application/octet-stream');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        async downloadImg1(imageUrl: any, filename: any) {
            const image = await this.loadImage({src: imageUrl})
            let base64 = this.img2Base64(image)
            let blob = this.base64ToBlob(base64)
            // 下载图片
            this.downloadFile(URL.createObjectURL(blob), filename + '.png');

        }

        handleClose() {
            this.detailDialog = false
        }

        // 搜索 search:true 重置翻页
        searchTable(search = false) {
            this.loading = true;
            if (search) {
                this.search.pagination = {
                    pageSize: 20,
                    page: 0,
                };
            }
            api
                .post('J27522', {...this.search, questId: this.$route.query.questId})
                .then(res => {
                    this.total = res.total;
                    this.tableData = res.list;
                }).finally(() => {
                this.loading = false;
            })
        }

        // 编辑
        toEdit(row: any) {
            this.$router.push({
                name: 'activityAdd',
                query: {
                    activityNumber: row.activityNumber
                }
            })
        }

        // 查看
        detailDialog = false
        detailInfo: any = {}

        toDetail(row: any) {
            this.detailInfo = row
            this.detailDialog = true

        }


        jifenDialog = false
        info: any = {}

        toSend(row: any) {
            this.info = row
            this.jifenDialog = true
        }

        handleCloseJF() {
            this.jifenDialog = false
        }

        model: any = {
            OSSAccessKeyId: "",
            signature: "",
            policy: "",
            key: "",
            callback: "",
            file: "",
            success_action_status: ''
        };
        fileList: any = {
            image_url: '',
            image_name: ''
        }
        file = {
            fileCode: '',
            fileName: '',
        }

        getData(item: any) {
            console.log('iii', item);
            this.file = {
                fileName: item.image_name,
                fileCode: item.image_url
            }
        }

        activated() {
            this.searchTable();
        }
    }
