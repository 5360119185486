









import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import { Message } from "element-ui";
import api from "@/api";
import {imageUrl} from '@/utils/common'

@Component({
    name: "uploadNew",
    components: {}
})

export default class extends Vue {
    // eslint-disable-next-line @typescript-eslint/ban-types
    // @Prop({ default: true }) private dataAuth!: {};
    @Prop({ default: {} }) private model!: any;
    @Prop({ default: {image_name: '', image_url: ''} }) private fileList!: any;

    baseUrl = imageUrl

    dataAuth = {}
    handleAvatarSuccess(res: any, file: any) {
        console.log('file', file, res);
        if(res.errcode == 0) {
            this.fileList.image_name = file.name;
            this.fileList.image_url = res.data;
            this.$emit('getData', res.data)
            this.$emit('getData2', this.fileList)
        } else {
            this.$message.error('上传失败')
        }

    }

    beforeAvatarUpload(file: any) {
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
            this.$message.error("上传图片只能是 JPG/PNG 格式!");
        }
        if (!isLt2M) {
            this.$message.error("上传图片大小不能超过 2MB!");
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _that = this;
        return new Promise(function (resolve, reject) {
            if (isJPG && isLt2M) {
                api
                    .post("J27501", {
                        fileName: file.name,
                        softLink: ''
                    })
                    .then((data) => {
                        _that.dataAuth = data;
                        resolve(true);
                    })
                    .catch(() => {
                        reject(false);
                    });
            } else {
                reject(false);
            }
        });
    }

}
